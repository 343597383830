<ng-container>
  <div
    #blockElement
    class="container"
    [ngClass]="{
      'speech-border': isSpeaker(this.speakers),
      isOwner: isOwnerVideo
    }"
  >
    <video
      #videoElement
      [style.display]="videoDisabled || loading || away ? 'none' : ''"
      [ngClass]="{
        'without-lesson-link': !owner && isMobile,
        'full-screen': isIOS && isFullScreen,
        'full-screen-portrait': isPortrait && isFullScreen && isIOS
      }"
    ></video>

    <div
      *ngIf="loading"
      class="loader-block"
    >
      <img src="assets/img/svg/video-loading.svg" />
    </div>

    <div
      *ngIf="((member && videoDisabled && !loading) || !streamManagerLoaded) && !away"
      [ngClass]="{
        'without-lesson-link': !owner && isMobile,
        'add-blur': isTooltipOpen,
        'full-screen': isIOS && isFullScreen,
        'full-screen-portrait': isPortrait && isFullScreen && isIOS
      }"
      class="video-disabled"
    >
      <div
        class="aside_profile__icon"
        [ngClass]="{ 'extended-grid': mobileExtendedGrid }"
      >
        <div class="avatar-image"
          [ngClass]="{ 'extended-grid': mobileExtendedGrid }"
          *ngIf="member?.avatarFileName; else noAvatarBlock"
          [style.background]="member | userAvatarBackground"
        ></div>
        <ng-template #noAvatarBlock>
          <span>{{ member | userInitials }}</span>
        </ng-template>
      </div>
    </div>
    <div
      (click)="openFullScreen()"
      class="full-screen__button-portrait"
      *ngIf="isPortrait && !isFullScreen && isOwnerVideo"
    >
      <img
        src="/assets/img/svg/full-screen.svg"
        alt="fullscreen"
        class="full-screen-icon"
      />
    </div>
    <div
      *ngIf="isFullScreen && isIOS"
      (click)="closeFullScreen()"
      class="full-screen__button-close"
      [ngClass]="{ 'full-screen__button-close-portrait': isPortrait }"
    >
      <img src="assets/img/svg/icon-close_grey-thin.svg" />
    </div>


    <ng-content></ng-content>
  </div>

  <div class="member_header">
    <div class="member_cam-mic">
      <div
        class="member_mic-toggle"
        *ngIf="isMuted"
      >
        <img src="assets/img/svg/group-lesson-next/mic-off.svg" />
      </div>
      <div
        class="member_cam-toggle"
        *ngIf="videoDisabled"
      >
        <img src="assets/img/svg/group-lesson-next/cam-off.svg" />
      </div>
      <div
        class="member_mic-toggle"
        *ngIf="!isMuted"
      >
        <img src="assets/img/svg/group-lesson-next/mic-on.svg" />
      </div>
      <div
        class="member_cam-toggle"
        *ngIf="!videoDisabled"
      >
        <img src="assets/img/svg/group-lesson-next/cam-on.svg" />
      </div>
    </div>
    <span class="member_fio">{{ name }}</span>

    <div
      class="disconnected"
      *ngIf="member.isDisconnected"
    ></div>
  </div>
  <div class="member_footer">
    <div class="member_cam-mic">
      <div
        class="member_mic-toggle"
        *ngIf="isMuted"
      >
        <img src="assets/img/svg/group-lesson-next/mic-off.svg" />
      </div>
      <div
        class="member_cam-toggle"
        *ngIf="videoDisabled"
      >
        <img src="assets/img/svg/group-lesson-next/cam-off.svg" />
      </div>
      <div
        class="member_mic-toggle"
        *ngIf="!isMuted"
      >
        <img src="assets/img/svg/group-lesson-next/mic-on.svg" />
      </div>
      <div
        class="member_cam-toggle"
        *ngIf="!videoDisabled"
      >
        <img src="assets/img/svg/group-lesson-next/cam-on.svg" />
      </div>
    </div>
    <span class="member_role">{{ memberRoleName | transloco }} </span>
  </div>
</ng-container>