<div
  class="container"
  [class.hovered]="lesson.isHovered"
  (click)="openMeetingPopup()"
>
  <div class="header">
    <div class="date-time">
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.6h-.833V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234A.784.784 0 0 0 10.5.8v.8h-5V.8a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234.851.851 0 0 0-.589.234.784.784 0 0 0-.244.566v.8H3c-.663 0-1.299.253-1.768.703A2.352 2.352 0 0 0 .5 4v9.6c0 .636.263 1.247.732 1.697.47.45 1.105.703 1.768.703h10c.663 0 1.299-.253 1.768-.703.469-.45.732-1.06.732-1.697V4c0-.637-.263-1.247-.732-1.697A2.554 2.554 0 0 0 13 1.6ZM3 3.2h.833V4c0 .212.088.416.244.566.157.15.369.234.59.234a.851.851 0 0 0 .589-.234A.784.784 0 0 0 5.5 4v-.8h5V4c0 .212.088.416.244.566.156.15.368.234.59.234a.851.851 0 0 0 .589-.234.784.784 0 0 0 .244-.566v-.8H13c.221 0 .433.084.59.234.155.15.243.354.243.566v3.2H2.167V4c0-.212.087-.416.244-.566A.851.851 0 0 1 3 3.2Zm10 11.2H3a.851.851 0 0 1-.59-.234.784.784 0 0 1-.243-.566V8.8h11.666v4.8a.784.784 0 0 1-.244.566.852.852 0 0 1-.589.234Z"
            fill="#678AFF"
          />
          <path
            d="M4.8 12.1a.8.8 0 1 0 0-1.6.8.8 0 0 0 0 1.6ZM11.167 10.5H7.833a.851.851 0 0 0-.589.234A.784.784 0 0 0 7 11.3c0 .212.088.416.244.566.156.15.368.234.59.234h3.333a.851.851 0 0 0 .589-.234A.784.784 0 0 0 12 11.3a.784.784 0 0 0-.244-.566.851.851 0 0 0-.59-.234Z"
            fill="#678AFF"
          />
        </svg>
      </div>
      <div
        class="date"
        *ngIf="lessonDate"
      >
        {{ lessonDate }}
      </div>
      <div
        class="time"
        *ngIf="lessonDateTime"
      >
        {{ lessonDateTime }}
      </div>
    </div>

    <div
      class="select-checkbox"
      *ngIf="activeTab?.id === 'history'"
    >
      <ng-content select="[first]"></ng-content>
    </div>

    <div
      class="btn"
      *ngIf="activeTab?.id === 'active' || activeTab == null"
    >
      <svg
        width="4"
        height="14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="2"
          cy="2"
          r="1.5"
          fill="#678AFF"
        />
        <circle
          cx="2"
          cy="7"
          r="1.5"
          fill="#678AFF"
        />
        <circle
          cx="2"
          cy="12"
          r="1.5"
          fill="#678AFF"
        />
      </svg>

      <div
        class="menu"
        (click)="stopPropagationtMenu($event)"
      >
        <div class="menu-inner">
          <div
            class="item"
            (click)="copyLink()"
          >
            <div
              class="icon"
              *ngIf="!timeoutCopyLink"
            >
              <svg
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="m10.288 11.991 4.593-3.85a.78.78 0 0 0 0-1.209l-4.593-3.85a.843.843 0 0 0-1.39.604v1.519C2.448 4.088.832 9.859.832 13.117c1.496-2.482 5.373-6.981 8.066-3.258v1.523a.843.843 0 0 0 1.39.609Z"
                  stroke="#3DD598"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <div
              class="icon"
              *ngIf="timeoutCopyLink"
            >
              <svg
                width="14"
                height="14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.667 4.667H9.333v-2.59A2.084 2.084 0 0 0 7.257 0h-5.18A2.084 2.084 0 0 0 0 2.077v5.18a2.084 2.084 0 0 0 2.077 2.076h2.59v2.334A2.333 2.333 0 0 0 7 14h4.667A2.333 2.333 0 0 0 14 11.667V7a2.333 2.333 0 0 0-2.333-2.333ZM4.667 7v.778h-2.59a.521.521 0 0 1-.521-.521v-5.18a.521.521 0 0 1 .52-.521h5.18a.521.521 0 0 1 .522.52v2.59H7A2.333 2.333 0 0 0 4.667 7Z"
                  fill="#3DD598"
                />
              </svg>
            </div>
            <div class="title">{{ timeoutCopyLink ? 'Скопировано' : 'Поделиться' }}</div>
          </div>
          <div
            class="item"
            *ngIf="
              userdata?.isTutor && !isScheduled && lesson?.lessonFormatId !== 'Scheduled' && lesson?.discipline?.name
            "
            (click)="openEditMeetingPopup()"
          >
            <div class="icon">
              <svg
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#a)">
                  <path
                    d="m9.652 2.455.649-.649A2.753 2.753 0 1 1 14.194 5.7l-.65.649M9.653 2.455s.081 1.379 1.298 2.595c1.216 1.217 2.595 1.298 2.595 1.298M9.652 2.455 3.687 8.42c-.404.404-.606.606-.78.829-.204.262-.38.547-.523.848-.122.255-.212.525-.393 1.068L1.412 12.9l-.187.561m12.32-7.113L7.58 12.313c-.404.404-.606.606-.829.78a4.6 4.6 0 0 1-.848.524c-.255.121-.525.211-1.068.392l-1.735.579-.561.187M1.225 13.46l-.187.562a.742.742 0 0 0 .94.939l.56-.187M1.226 13.46l1.314 1.314"
                    stroke="#678AFF"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path
                      fill="#fff"
                      d="M0 0h16v16H0z"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="title">{{ 'Редактировать' | transloco }}</div>
          </div>
          <div
            class="item"
            *ngIf="userdata?.isTutor && lesson?.lessonFormatId === 'Group' && lesson?.discipline?.name"
            (click)="cancelLesson(lesson.id)"
          >
            <div class="icon">
              <svg
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke="#FC5A5A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="bevel"
                  d="m3.039 12.946 9.9-9.9M3.055 3.04l9.899 9.9"
                />
              </svg>
            </div>
            <div class="title">{{ 'Отменить' | transloco }}</div>
          </div>
          <div
            class="item"
            *ngIf="
              lesson?.lessonFormatId === 'Instant' &&
              (lesson?.lessonStatusId === 'Initiated' || lesson?.lessonStatusId === 'ReadyToStart')
            "
            (click)="cancelChatRequestLesson(lesson.joinIdentity)"
          >
            <div class="icon">
              <svg
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke="#FC5A5A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="bevel"
                  d="m3.039 12.946 9.9-9.9M3.055 3.04l9.899 9.9"
                />
              </svg>
            </div>
            <div class="title">{{ 'Отменить' | transloco }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="controls">
      <div
        class="publicLesson"
        *ngIf="lesson?.lessonFormatId !== 'Scheduled'"
      >
        {{ isPublicLesson ? 'Открытое занятие' : ('Закрытое занятие' | transloco) }}
      </div>
      <div
        class="watch-video"
        routerLink="/history/{{ lesson?.id }}"
        *ngIf="
          activeTab?.id === 'history' &&
          lesson?.postProcessingCompleted &&
          !isDateExpire &&
          lesson?.videoFileUrl != null
        "
      >
        {{ 'Смотреть запись' | transloco }}
      </div>
      <div
        class="watch-video"
        *ngIf="activeTab?.id === 'history' && lesson?.postProcessingCompleted && isDateExpire"
      >
        {{ 'Время хранения истекло' | transloco }}
      </div>
      <div
        class="watch-video"
        routerLink="/history/{{ lesson?.id }}"
        *ngIf="activeTab?.id === 'history' && !lesson.postProcessingCompleted"
      >
        {{ 'Запись обрабатывается' | transloco }}
      </div>
    </div>
    <div
      class="discipline"
      *ngIf="disciplineName"
    >
      {{ disciplineName }}
    </div>
    <div
      class="themeLesson"
      *ngIf="themeLesson"
    >
      {{ themeLesson }}
    </div>
  </div>
  <div class="footer">
    <div
      class="members"
      *ngIf="membersDisplayText"
    >
      <div class="icon">
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.8 13.12A5.72 5.72 0 0 1 7.52 7.4h.96a5.72 5.72 0 0 1 5.72 5.72A1.88 1.88 0 0 1 12.32 15H3.68a1.88 1.88 0 0 1-1.88-1.88Z"
            stroke="#678AFF"
            stroke-width="2"
          />
          <circle
            cx="8"
            cy="4"
            r="3"
            stroke="#678AFF"
            stroke-width="2"
          />
        </svg>
      </div>
      <div class="text">
        {{ membersDisplayText }}
      </div>
    </div>
  </div>

  <div
    class="place-popup"
    #placePopupRef
  ></div>
</div>
